import { HttpClient, HttpResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ClassType } from 'class-transformer-validator'
import { Subject } from 'rxjs'
import { ConfigService } from 'src/app/services/config.service'
import { MemoryStorageService } from 'src/app/services/memory-storage.service'
import { Context } from '../api-structures/admin/user'
import { DataTransformer } from '../api-structures/dataTransformer'
import { AvailableScreensService } from './available-screens.service'
import { getCompanyType } from './companyType.service'
import { LanguageService } from './language.service'

interface IProxyOptions<TRequest, TResponse> {
    endpoint: string
    body?: TRequest
    token?: string
    context?: Partial<Context>
    responseType?: ClassType<TResponse>
    errorMessage?: string
}

interface IOptions<T> {
    route: string
    headers?: Record<string, string>
    body?: {
        data?: T
        context?: Partial<Context>
    } |
    {
        password: string
    } |
    {
        code: string
    }
    isHideProgress?: boolean
    errorMessage?: string
    noLogoutOn401?: boolean
}

export interface IWrapperError {
    isWrapperError: boolean
    errorMessage: string
    err: any
}

@Injectable({ providedIn: 'root' })
export class ApiService {
    private url(route: string) {
        return `${this.configService.config.api_endpoint}${route}`
    }

    private onRequests = new Subject<{ w: 'start' | 'done' | 'error' | 'show-loading' | 'hide-loading', data?: any, isHideProgress?: boolean, route?: string, options?: any, noLogoutOn401?: boolean }>()

    onRequest$ = this.onRequests.asObservable()

    constructor(
        private http: HttpClient,
        private transformer: DataTransformer,
        private languageService: LanguageService,
        private configService: ConfigService,
        private memoryStorageService: MemoryStorageService,
        private availableScreensService: AvailableScreensService
    ) { }

    async callbackRequest<T>(fun: (http: HttpClient) => Promise<T>, isHideProgress: boolean = false, noLogoutOn401: boolean = false) {
        this.onRequests.next({ w: 'start', isHideProgress, route: 'callbackRequest', noLogoutOn401 })
        try {
            const data = await fun(this.http)
            this.onRequests.next({ w: 'done', data: data, isHideProgress, route: 'callbackRequest', noLogoutOn401 })
        } catch (err) {
            this.onRequests.next({ w: 'error', data: err, isHideProgress, route: 'callbackRequest', noLogoutOn401 })
            throw err
        }
    }

    showLoading() {
        this.onRequests.next({ w: 'show-loading', isHideProgress: false })
    }

    hideLoading() {
        this.onRequests.next({ w: 'hide-loading', isHideProgress: false })
    }

    async rawRequest<TRequest, TResult>(options?: IOptions<TRequest>) {
        const { route, body, isHideProgress, errorMessage, headers, noLogoutOn401 } = options

        try {
            this.onRequests.next({ w: 'start', isHideProgress, route, noLogoutOn401 })

            if (!window.navigator.onLine) {
                throw {
                    err: null,
                    errorMessage: this.languageService.translateSync('OfflineError'),
                    isWrapperError: true,
                } as IWrapperError
            }

            const res = await
                this.http.post(
                    this.url(route),
                    body,
                    {
                        headers: { 'Access-Control-Allow-Origin': '*', ...headers, },
                        observe: 'response',
                        withCredentials: true
                    }
                ).toPromise() as HttpResponse<TResult>


            this.availableScreensService.setFromHeader(res.headers.get('availableScreens'))
            this.onRequests.next({ w: 'done', data: res, isHideProgress, route, noLogoutOn401 })

            return res.body as TResult
        } catch (err) {
            let ferr = err

            if (errorMessage !== undefined && ![401, 422].includes(err.status)) {
                const newErr: IWrapperError = {
                    err: err,
                    errorMessage: errorMessage,
                    isWrapperError: true,
                }
                ferr = newErr
            }

            this.onRequests.next({ w: 'error', data: ferr, isHideProgress, route, noLogoutOn401 })
            throw ferr
        }
    }

    miscRequest<TRequest extends object = never, TResult extends object = object>(options: IProxyOptions<TRequest, TResult>, moreOptions?: Partial<IOptions<TResult>>) {
        return this.proxyRequest('/p', options, moreOptions)
    }

    adminRequest<TRequest extends object = never, TResult extends object = object>(options: IProxyOptions<TRequest, TResult>, moreOptions?: Partial<IOptions<TResult>>) {
        return this.proxyRequest('/admin', options, moreOptions)
    }

    organizationAdminRequest<TRequest extends object = never, TResult extends object = object>(options: IProxyOptions<TRequest, TResult>, moreOptions?: Partial<IOptions<TResult>>) {
        return this.proxyRequest<TRequest, TResult>('/organizationAdmin', options, moreOptions)
    }

    superRequest<TRequest extends object = never, TResult extends object = object>(options: IProxyOptions<TRequest, TResult>, moreOptions?: Partial<IOptions<TResult>>) {
        return this.proxyRequest<TRequest, TResult>('/superAdmin', options, moreOptions)
    }

    unsecureRequest<TRequest extends object = never, TResult extends object = object>(route, options: IProxyOptions<TRequest, TResult>, moreOptions?: Partial<IOptions<TResult>>) {
        return this.proxyRequest(route, options, moreOptions)
    }

    private async proxyRequest<TRequest extends object, TResult extends object>(route: string, proxyOptions: IProxyOptions<TRequest, TResult>, options: Partial<IOptions<TResult>> = {}) {
        const { endpoint, body, context, responseType } = proxyOptions
        const headers = options.headers || {} as Record<string, string>
        const verifiedRoute = route.includes(endpoint) ? route : route + endpoint
        const realBody: { data: TRequest, context: Partial<Context> } = { data: body, context }

        let token: string | undefined
        if (getCompanyType() === 'MC1') {
            token = this.memoryStorageService.token || this.memoryStorageService.superToken
            headers['Authorization'] = 'Bearer ' + token
        }

        options.errorMessage = proxyOptions.errorMessage

        const fullOptions: IOptions<TRequest> = {
            ...options,
            route: verifiedRoute,
            headers,
            body: realBody,
        }

        const response = await this.rawRequest<TRequest, TResult>(fullOptions)

        if (responseType !== undefined) {
            const result = await this.transformer.transform(responseType, response)
            return result
        } else {
            return response
        }
    }
}