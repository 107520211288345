<div mat-dialog-content>
	<form [formGroup]="planogramForm">
		<mat-tab-group #tabGroup animationDuration="0ms" mat-stretch-tabs="true" dynamicHeight
			(selectedTabChange)="changeTab($event.index)">
			<mat-tab label="{{'PlanogramDetails'|translate}}">
				<div class="tab-container">
					<div class="row">
						<div class="col-sm-4">
							<mat-form-field>
								<input *ngIf="!planogram.mc1PlanogramId" matInput formControlName="codeCtrl" required
									[(ngModel)]="planogram.id" placeholder="{{'code'|translate}}" required>
								<input *ngIf="planogram.mc1PlanogramId" matInput formControlName="codeCtrl" required
									[(ngModel)]="planogram.mc1PlanogramId" placeholder="{{'code'|translate}}" required>
							</mat-form-field>
						</div>
						<div class="col-sm-8">
							<mat-form-field>
								<input matInput [(ngModel)]="planogram.name" placeholder="{{'PlanogramName'|translate}}"
									formControlName="nameCtrl" required>
							</mat-form-field>
						</div>
					</div>
					<div class="row">
						<div class="col-sm-6">
							<mat-form-field>
								<mat-select [(value)]="planogram.planogramType" required
									placeholder="{{'PlanogramType'|translate}}" (selectionChange)="fillUpCommonData()">
									<mat-option value="free">{{'Free'|translate}}</mat-option>
									<mat-option value="planogram">{{'Fixed'|translate}}</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
						<div class="col-sm-6">
							<mat-form-field>
								<mat-select [(value)]="planogram.shareOfShelfType" required
									placeholder="{{'shareOfShelfType'|translate}}">
									<mat-option value="linear">{{'linear'|translate}}</mat-option>
									<mat-option value="faces">{{'facesCounting'|translate}}</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
						<div class="col-sm-12" [hidden]="true">
							<mat-form-field>
								<mat-select [(value)]="planogram.staticModelsIds"
									placeholder="{{'StaticModels'|translate}}" multiple>
									<mat-option *ngFor="let staticModel of staticModels" [value]="staticModel.id">
										{{staticModel.name}}</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
					</div>
					<div>
						<mat-form-field>
							<input matInput [(ngModel)]="planogram.description"
								placeholder="{{'Description'|translate}}" formControlName="descriptionCtrl">
						</mat-form-field>
					</div>
				</div>
			</mat-tab>
			<mat-tab label="{{'Parameters'|translate}}">
				<div class="tab-container">
					<div class="table-container">
						<table class="custom-table">
							<thead>
								<tr>
									<th>{{'Enable'|translate}}</th>
									<th>{{'Parameter'|translate}}</th>
									<th>{{'Value'|translate}}</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>
										<mat-slide-toggle [(ngModel)]="parametersToggle.classificationThresholdToggle"
											formControlName="classificationThresholdToggle"></mat-slide-toggle>
									</td>
									<td>
										<div class="flex-column">
											<div>
												{{'ClassificationThreshold'|translate}}
											</div>
											<div>
												<span class="sub-info">
													{{'ClassificationThresholdDescription'|translate}}
												</span>
											</div>
										</div>
									</td>
									<td>
										<div [hidden]="!parametersToggle.classificationThresholdToggle"
											class="flex-column">
											<div class="level-container">
												<span class="sub-info">{{'ThresholdLevel'|translate}} ({{
													planogram.parameters.classificationThreshold }}%)</span>
											</div>
											<div class="level-container">
												<mat-slider class="my-slider-2" min="0" max="100" step="1"
													[value]="planogram.parameters.classificationThreshold"
													[(ngModel)]="planogram.parameters.classificationThreshold"
													[ngModelOptions]="{standalone: true}">
												</mat-slider>
											</div>
										</div>
									</td>
								</tr>

								<tr>
									<td>
										<mat-slide-toggle formControlName="autoCropToggle"
											[(ngModel)]="parametersToggle.autoCropToggle"></mat-slide-toggle>
									</td>
									<td>
										<div class="flex-column">
											<div>
												{{'AutoCrop'|translate}}
											</div>
											<div>
												<span class="sub-info">{{'AutoCropDescriptionText'|translate}}</span>
											</div>
										</div>
									</td>
									<td>
									</td>
								</tr>

								<tr>
									<td>
										<mat-slide-toggle formControlName="kpisFullToggle"
											[(ngModel)]="parametersToggle.kpisFullToggle"></mat-slide-toggle>
									</td>
									<td>
										<div class="flex-column">
											<div>
												{{'KPIsFull'|translate}}
											</div>
											<div>
												<span class="sub-info">{{'KpisDescriptionTextFull'|translate}}</span>
											</div>
										</div>
									</td>
									<td>
										<mat-form-field>
											<mat-select [(value)]="planogram.parameters.kpisFull" placeholder="Kpis"
												[disabled]="!parametersToggle.kpisFullToggle" multiple>
												<mat-option *ngFor="let option of kpisFullOptions"
													[value]="option.key">{{option.description}}</mat-option>
											</mat-select>
										</mat-form-field>
									</td>
								</tr>

								<tr>
									<td>
										<mat-slide-toggle formControlName="kpisSimpleToggle"
											[(ngModel)]="parametersToggle.kpisSimpleToggle"></mat-slide-toggle>
									</td>
									<td>
										<div class="flex-column">
											<div>
												{{'KPIsSimple'|translate}}
											</div>
											<div>
												<span class="sub-info">{{'KpisDescriptionTextSimple'|translate}}</span>
											</div>
										</div>
									</td>
									<td>
										<mat-form-field>
											<mat-select [(value)]="planogram.parameters.kpisSimple" placeholder="Kpis"
												[disabled]="!parametersToggle.kpisSimpleToggle" multiple>
												<mat-option *ngFor="let option of kpisSimpleOptions"
													[value]="option.key">{{option.description}}</mat-option>
											</mat-select>
										</mat-form-field>
									</td>
								</tr>

								<tr>
									<td>
										<mat-slide-toggle formControlName="detectionZonesToggle"
											[(ngModel)]="parametersToggle.detectionZonesToggle"></mat-slide-toggle>
									</td>
									<td>
										<div class="flex-column">
											<div>
												{{'DetectionZones'|translate}}
											</div>
											<div>
												<span
													class="sub-info">{{'DetectionZonesDescriptionText'|translate}}</span>
											</div>
										</div>
									</td>
									<td>
										<mat-form-field>
											<mat-select [(value)]="planogram.parameters.detectionZones"
												placeholder="Zones Selection"
												[disabled]="!parametersToggle.detectionZonesToggle" multiple>
												<mat-option *ngFor="let option of detectionZonesOptions"
													[value]="option.key">{{option.description}}</mat-option>
											</mat-select>
										</mat-form-field>
									</td>
								</tr>

								<tr>
									<td>
										<mat-slide-toggle formControlName="annotationsToggle"
											[(ngModel)]="parametersToggle.annotationsToggle"></mat-slide-toggle>
									</td>
									<td>
										<div class="flex-column">
											<div>
												{{'Annotations'|translate}}
											</div>
											<div>
												<span class="sub-info"> {{'AnnotationsDescriptionText'|translate}}
												</span>
											</div>
										</div>
									</td>
									<td>
										<mat-form-field>
											<mat-select [(value)]="planogram.parameters.annotations"
												placeholder="Annotations Selection"
												[disabled]="!parametersToggle.annotationsToggle" multiple>
												<mat-option *ngFor="let option of annotationsOptions"
													[value]="option.key">{{option.description}}</mat-option>
											</mat-select>
										</mat-form-field>
									</td>
								</tr>

								<tr>
									<td>
										<mat-slide-toggle formControlName="priceOcrToggle"
											[(ngModel)]="parametersToggle.priceOcrToggle"></mat-slide-toggle>
									</td>
									<td>
										<div class="flex-column">
											<div>
												{{'PriceOCR'|translate}}
											</div>
											<div>
												<span class="sub-info"> {{'PriceOCRDescriptionText'|translate}}</span>
											</div>
										</div>
									</td>
									<td>
										<div [hidden]="!parametersToggle.priceOcrToggle">
											<mat-button-toggle-group name="fontStyle" aria-label="Font Style"
												class="priceOcrContainer"
												[(ngModel)]="planogram.parameters.priceOcrPicture"
												[ngModelOptions]="{standalone: true}">
												<mat-button-toggle value="multi" class="priceOcrOption">Multi Picture
													OCR</mat-button-toggle>
												<mat-button-toggle value="single" class="priceOcrOption">Single Picture
													OCR</mat-button-toggle>
											</mat-button-toggle-group>
										</div>
									</td>
								</tr>

								<tr>
									<td>
										<mat-slide-toggle formControlName="syncOnlyEditedResults"
											[(ngModel)]="planogram.parameters.syncOnlyEditedResults"></mat-slide-toggle>
									</td>
									<td>
										<div class="flex-column">
											<div>
												{{'SyncOnlyEditedResults'|translate}}
											</div>
											<div>
												<span
													class="sub-info">{{'SyncOnlyEditedResultsDescriptionText'|translate}}</span>
											</div>
										</div>
									</td>
									<td>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</mat-tab>
		</mat-tab-group>
	</form>
</div>

<div mat-dialog-actions>
	<button mat-stroked-button color="primary" (click)="onCancelClick()">{{'CloseModalButton'|translate}}</button>
	<button mat-flat-button color="primary" cdkFocusInitial (click)="save()">{{'ConfirmModalButton'|translate}}</button>
</div>