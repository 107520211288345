import { Expose, Type } from 'class-transformer'
import { IsArray, IsBoolean, IsDate, IsIn, IsNumber, IsOptional, IsString, ValidateNested } from 'class-validator'
import { DateType } from '../customDecorators'

export class Rect {
  @Expose() @IsOptional() @IsNumber() x?: number
  @Expose() @IsOptional() @IsNumber() y?: number
  @Expose() @IsOptional() @IsNumber() width?: number
  @Expose() @IsOptional() @IsNumber() height?: number
}

export class Item {
  @Expose() @IsIn(['space', 'product', 'category', 'brand']) @IsOptional() itemType?: 'space' | 'product' | 'category' | 'brand'
  @Expose() @Type(() => Rect) @ValidateNested() @IsOptional() rect?: Rect
  @Expose() @Type(() => Rect) @ValidateNested() @IsOptional() absolutePoint?: Rect

  @Expose() @IsOptional() @IsString() categoryId?: string
  @Expose() @IsOptional() @IsString() brandId?: string

  @Expose() @IsOptional() @IsString() productId?: string
  @Expose() @IsOptional() @IsString() imagesCatalogId?: string
  @Expose() @IsOptional() @IsBoolean() isTrained?: boolean
  @Expose() @IsOptional() @DateType() @IsDate() lastUpdadeCatalog?: Date
  @Expose() @IsOptional() @IsString() imgUrl?: string // TODO: This should be taken from the image provider
  @Expose() @IsOptional() @IsString() name?: string
  @Expose() @IsOptional() @IsString() ean?: string
  @Expose() @IsOptional() @IsNumber() column?: number
}

export class Area {
  @Expose() @IsIn(['vertical', 'horizontal', 'seperator']) areaType!: 'vertical' | 'horizontal' | 'seperator'
  @Expose() @Type(() => Rect) @ValidateNested() @IsOptional() rect?: Rect
  @Expose() @Type(() => Item) @ValidateNested() @IsArray() @IsOptional() items?: Item[]
}

export class Structure {
  @Expose() @IsNumber() width!: number
  @Expose() @IsNumber() height!: number

  @Expose() @Type(() => Area) @ValidateNested() @IsArray() @IsOptional() areas?: Area[]
}

export class Dimensions {
  @Expose() @IsNumber() depth!: number
  @Expose() @IsNumber() height!: number
  @Expose() @IsNumber() width!: number
}

export class ImageCatalog {
  @Expose() @IsString() id!: string
  @Expose() @IsString() name!: string
  @Expose() @IsString() thumbnailUrl!: string
  @Expose() @DateType() @IsDate() lastUpdate!: Date

  @Expose() @Type(() => Dimensions) @ValidateNested() @IsOptional() dimensions?: Dimensions
}

export class Product {
  @Expose() @IsString() id!: string
  @Expose() @IsOptional() @IsString() categoryId?: string
  @Expose() @IsString() @IsOptional() mc1ProductId?: string
  @Expose() @IsString() @IsOptional() name?: string

  @Expose() @Type(() => ImageCatalog) @ValidateNested() @IsArray() @IsOptional() imageCatalogs?: ImageCatalog[]
}

export class PlanogramParameters {
  @Expose() @IsNumber() @IsOptional() classificationThreshold?: number;
  @Expose() @IsBoolean() @IsOptional() autoCrop?: boolean;
  @Expose() @IsArray() @IsOptional() kpisFull: string[];
  @Expose() @IsArray() @IsOptional() kpisSimple: string[];
  @Expose() @IsArray() @IsOptional() detectionZones?: string[];
  @Expose() @IsArray() @IsOptional() annotations?: string[];
  @Expose() @IsString() @IsOptional() priceOcrPicture?: string;
  @Expose() @IsBoolean() @IsOptional() syncOnlyEditedResults?: boolean;
}

export class Root {
  @Expose() @IsString() name!: string
  @Expose() @IsString() @IsOptional() mc1PlanogramId?: string
  @Expose() @DateType() @IsDate() validity!: Date
  @Expose() @Type(() => String) @IsOptional() @IsArray() @IsString({ each: true }) staticModelsIds?: string[]
  @Expose() @IsIn(['draft', 'published', 'deprecated']) state!: 'draft' | 'published' | 'deprecated'
  @Expose() @DateType() @IsDate() lastUpdate!: Date
  @Expose() @IsOptional() @IsString() description?: string
  @Expose() @IsOptional() @IsString() comment?: string
  @Expose() @IsOptional() @IsString() recomendMPV?: string
  @Expose() @IsOptional() @IsIn(['Planogram', 'planogram', 'Free', 'free']) planogramType?: 'Planogram' | 'planogram' | 'Free' | 'free'
  @Expose() @IsIn(['faces', 'linear']) @IsOptional() shareOfShelfType?: 'faces' | 'linear'
  @Expose() @IsBoolean() @IsOptional() invasorKPI?: boolean

  @Expose() @IsOptional() @IsString() equipmentId?: string
  @Expose() @IsOptional() @IsString() imageUrl?: string
  @Expose() @IsOptional() @IsString() imagePath?: string
  @Expose() @IsOptional() @IsString() extra?: string

  @Expose() @Type(() => Structure) @ValidateNested() @IsOptional() structure?: Structure
  @Expose() @Type(() => Product) @ValidateNested() @IsArray() @IsOptional() products?: Product[]
  @Expose() @IsString() @IsOptional() imageImportedFromUrl?: string
  @Expose() @Type(() => PlanogramParameters) @ValidateNested() @IsOptional() parameters?: PlanogramParameters
}
