import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { DatePipe } from "@angular/common";
import {
  AfterViewInit,
  Component,
  ViewChild
} from "@angular/core";
import { MatChipInputEvent } from "@angular/material/chips";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSelectChange } from "@angular/material/select";
import { MatTabGroup } from "@angular/material/tabs";
import { Router } from "@angular/router";
import { Brand } from "src/app/shared/api-structures/misc/brand";
import { Category } from "src/app/shared/api-structures/misc/category";
import { EquipmentWithId } from "src/app/shared/api-structures/misc/equipment";
import { PlanogramWithId } from "src/app/shared/api-structures/misc/planogram";
import { Structure } from "src/app/shared/api-structures/misc/planogramStructure";
import { ProductWithCatalog, SearchProductsRequestFilters, SearchProductsRequestFiltersPlanogram } from "src/app/shared/api-structures/misc/product";
import { AutoDestroy } from "src/app/shared/base-directives/auto-destroy";
import { LanguageService } from "src/app/shared/services/language.service";
import { EquipmentService } from "src/app/super-admin/services/equipment.service";
import * as _ from "underscore";
import { Dal } from "../../../../dal/dal";
import { AppService } from "../../../../services/app.service";
import { DataService } from "../../../../services/data.service";
import { SnackbarService } from "../../../../services/snackbar.service";
import { UtilitiesService } from "../../../../services/utilities.service";
import { PLANOGRAM_STATUS } from "../../../../shared/const";
import { ConfirmDialogComponent } from "../../../general/confirm-dialog/confirm-dialog.component";
import { CreateEquipmentDialogComponent } from "../../create-equipment-dialog/create-equipment-dialog.component";
import { ImportFromImageDialogComponent } from "../../import-from-image-dialog/import-from-image-dialog.component";
import { BuilderArea, PlanogramBuilderComponent } from "../planogram-builder/planogram-builder.component";
import { ProductsFilterOptions } from "./products-filter-options.component";

export type CatalogData = {
  id: string;
  thumbnailUrl: string;
  catalogId: string;
  name: string;
  height: number;
  width: number;
  catalogName: string;
  ean: string;
  quantity: number;
  isTrained?: boolean;
  lastUpdateCatalog?: Date;
}

export type CategoryData = {
  id: string;
  name: string;
}

export type BrandData = {
  id: string;
  name: string;
}

export type AreaData = {
  areaType: 'horizontal' | 'vertical' | 'seperator';
}

@Component({
  selector: "app-planogram-planning",
  templateUrl: "./planogram-planning.component.html",
  styleUrls: ["./planogram-planning.component.scss"],
})
export class PlanogramPlanningComponent
  extends AutoDestroy
  implements AfterViewInit {

  @ViewChild('planogramBuilder') planogramBuilder: PlanogramBuilderComponent;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild('productTabs') productTabs: MatTabGroup

  planogram: PlanogramWithId;
  builderWidth: number;
  builderHeight: number;
  zoom: number = 1;
  equipmentWidth: number;
  equipmentHeight: number;
  currentEquipment: EquipmentWithId;
  isAllowCategories: boolean;
  isAllowBrands: boolean;
  planogramCanvasElement: HTMLCanvasElement;
  ctxPlanogramCanvas: CanvasRenderingContext2D;
  showSpinnerProducts: boolean = false;
  showProductSettings: boolean = false;
  products: CatalogData[] = [];
  totalProducts: number;

  productsFilters: string[] = [];
  productsShelfFilters: string[] = [];
  modalFilters: SearchProductsRequestFiltersPlanogram = {
    categories: null,
    brands: null,
    isCompetitor: null,
  };

  newFilters: SearchProductsRequestFilters = {
    productStatus: true
  };

  modalFiltersShelf: SearchProductsRequestFiltersPlanogram = {
    categories: null,
    brands: null,
    isCompetitor: null,
  };
  productsFiltersShowSpinner: false;
  readonly productsFiltersSeparatorKeysCodes: number[] = [ENTER, COMMA];

  emptySpaceWidth: number = 5;
  emptySpaceHeight: number = 5;
  category: Category = null;
  brand: Brand = null;
  categorySize: number = 20;
  brandSize: number = 20;
  ratio: number;
  mouseDown: boolean = false;
  isPlanogramItemDragging: boolean = false;
  // planogramItemHover: Item = undefined;
  planogramAreaHover;
  dragItemUrl: string = undefined;
  planogramCanvasCursor: string = "auto";
  isProductpanelOpen = true;
  isShelfItemspanelOpen = true;
  isPlanogramPanelOpen = true;
  isPlanogramInfoOpen = false;
  selectedShelfProductsCount: string = '';
  selectedShelfProducts: { [key: string]: CatalogData } = {};
  filteredShelfProducts: { [key: string]: CatalogData } = {};
  selectedArea: BuilderArea;
  jsonParser = JSON
  expandedImage = {};
  emptySpaceColor = 'rgba(108,134,210,0.3)';
  categoryColor = 'rgba(255,0,0,0.3)';
  brandColor = 'rgba(0,255,0,0.3)';

  constructor(
    public dialog: MatDialog,
    private snackbarService: SnackbarService,
    private utilitiesService: UtilitiesService,
    public dataService: DataService,
    private router: Router,
    private dal: Dal,
    public datepipe: DatePipe,
    private appService: AppService,
    private languageService: LanguageService,
    private equipmentService: EquipmentService
  ) {
    super();
  }

  areaChanged(data: { area: BuilderArea, quiet?: boolean }) {
    this.selectedArea = data.area
    this.getShelfItems()
    if (!data.quiet) {
      this.productTabs.selectedIndex = 1
    }
  }

  getEquipmentsPromise = async () => {
    const equipmentsRes = await this.dal.getEquipments([]);
    this.dataService.equipments = _.sortBy(equipmentsRes, "name");
  };

  async ngAfterViewInit() {
    let urlParams = this.utilitiesService.getUrlParams(window.location.href);
    if (!urlParams["planogram_id"]) {
      this.snackbarService.openSnackBar(
        2000,
        this.languageService.translateSync("InvalidUrl")
      );
      this.router.navigateByUrl("/");
      return;
    }

    let promises: Array<any> = [];
    const getProductsPromise = async () =>
      await this.searchProducts(true, false);
    const getPlanogramPromise = async () => {
      const planogramRes = await this.dal.getPlanogram(
        urlParams["planogram_id"]
      );
      this.planogram = planogramRes;
      this.isAllowCategories =
        this.planogram.staticModelsIds !== undefined &&
        this.planogram.staticModelsIds.length > 0;
      this.isAllowBrands =
        this.planogram.staticModelsIds !== undefined &&
        this.planogram.staticModelsIds.length > 0;
    };
    const getCategoriesPromise = async () => {
      const categoriesRes = await this.dal.getCategoriesWithIds();
      this.dataService.categoriesWithIds = _.sortBy(categoriesRes, "name");
    };

    const getBrandsPromise = async () => {
      const brandsRes = await this.dal.getBrandsWithIds();
      this.dataService.brandsWithIds = _.sortBy(brandsRes, "name");
    };

    promises.push(getPlanogramPromise());
    promises.push(this.getEquipmentsPromise());
    promises.push(getProductsPromise());
    promises.push(getCategoriesPromise());
    promises.push(getBrandsPromise());

    await Promise.all(promises);

    this.setEquipment();
  }
  setEquipment() {
    if (this.planogram.equipmentId) {
      this.currentEquipment = this.utilitiesService.cloneObject(
        _.find(
          this.dataService.equipments,
          (e) => e.id == this.planogram.equipmentId
        )
      );
      this.showEquipment();
    }
  }

  openFilterOptions(event) {
    event.stopPropagation();
    const dialogRef = this.dialog.open(ProductsFilterOptions, {
      width: "500px",
      data: this.modalFilters,
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data && !_.isEqual(this.modalFilters, data)) {
        this.modalFilters = data;

        this.searchProducts(true);
      }
    });
  }

  openShelfFilterOptions(event) {
    event.stopPropagation();
    const dialogRef = this.dialog.open(ProductsFilterOptions, {
      width: "500px",
      data: this.modalFiltersShelf,
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data && !_.isEqual(this.modalFiltersShelf, data)) {
        this.modalFiltersShelf = data;
        this.filterShelfProducts();
      }
    });
  }

  async searchProducts(goToFirstPage: boolean, isShowSpinner: boolean = true) {
    if (isShowSpinner) {
      this.showSpinnerProducts = true;
    }
    let pageIndex = +this.paginator?.pageIndex;
    let pageSize = +this.paginator?.pageSize;

    if (goToFirstPage) {
      pageIndex = 0;
      pageSize = 25;
    }

    try {
      const res = await this.dal.searchProductsForPlanogramPlanningDifferingCatalog(
        isShowSpinner,
        pageIndex,
        pageSize,
        this.productsFilters,
        true,
        this.newFilters,
        this.modalFilters
      );
      this.showSpinnerProducts = false;

      this.products = res.items.map(item => {
        const prod: CatalogData = {
          id: item.productId,
          thumbnailUrl: item.imageCatalogs[0].thumbnailUrl,
          height: item.imageCatalogs[0].height,
          width: item.imageCatalogs[0].width,
          catalogId: item.imageCatalogs[0].id,
          name: item.name,
          catalogName: item.imageCatalogs[0].name,
          ean: item.ean,
          isTrained: item.imageCatalogs[0].isTrained,
          lastUpdateCatalog: item.imageCatalogs[0].lastUpdate,
          quantity: 1
        }
        return prod;
      })
      this.totalProducts = res.totalProducts;
      if (this.paginator) {
        this.paginator.length = this.totalProducts;
        this.paginator.pageIndex = pageIndex;
      }
    } catch (err) {
      console.error('Error searching products', err)
      this.showSpinnerProducts = false;
    }
  }

  createEquipment() {
    let dialogRef = this.dialog.open(CreateEquipmentDialogComponent, {
      width: "1536px",
      height: "700px",
    });
    dialogRef.afterClosed().subscribe(async ({ equipment, mode }) => {
      if (mode === "close") {
        return;
      }
      this.currentEquipment = null
      const equipmentRes = await this.dal.addEquipment(equipment);
      this.dataService.equipments.push(
        this.utilitiesService.cloneObject(equipmentRes)
      );
      this.currentEquipment = this.utilitiesService.cloneObject(equipmentRes);
      this.showEquipment();
      this.planogram.equipmentId = this.currentEquipment.id;
    });
  }

  getEquipmentHeight() {
    return this.equipmentService.getEquipmentShelvesLength(this.currentEquipment);
  }

  showEquipment() {
    const isVertical = this.currentEquipment.isVertical;
    let backgroundColor = this.currentEquipment.backgroundColor;
    if (backgroundColor === undefined) {
      backgroundColor = "#00FFFF";
    }

    if (isVertical) {
      this.equipmentWidth = this.getEquipmentHeight();
      this.equipmentHeight = this.currentEquipment.width;

      this.builderHeight = 500;
      this.ratio = this.builderHeight / this.equipmentHeight;
      this.builderWidth = Math.ceil(this.equipmentWidth * this.ratio);
    } else {
      this.equipmentWidth = this.currentEquipment.width;
      this.equipmentHeight = this.getEquipmentHeight();

      this.builderWidth = 500;
      this.ratio = this.builderWidth / this.equipmentWidth;
      this.builderHeight = Math.ceil(this.equipmentHeight * this.ratio);
    }
  }

  getShelfItems() {
    const shelfItems = this.selectedArea.products
    const shelfProducts = shelfItems
      .map((v) => v as CatalogData)
    this.selectedShelfProductsCount = ''
    this.selectedShelfProducts = {}
    for (let p of shelfProducts) {
      if (!this.selectedShelfProducts[p.catalogId]) {
        const shelfProduct = this.utilitiesService.deepCopy(p)
        shelfProduct.quantity = 1
        this.selectedShelfProducts[p.catalogId] = shelfProduct
      } else {
        this.selectedShelfProducts[p.catalogId].quantity++;
      }
    }
    this.selectedShelfProductsCount = ` (${shelfProducts.length})`
    this.filteredShelfProducts = this.selectedShelfProducts
  }

  unselectShelf() {
    this.selectedShelfProducts = {}
    this.filteredShelfProducts = {}
    this.selectedShelfProductsCount = ''
    this.selectedArea = null
  }

  equipmentSelected(selectedItem: MatSelectChange) {
    this.currentEquipment = null
    this.zoom = 1
    this.unselectShelf()
    setTimeout(() => {
      this.currentEquipment = this.utilitiesService.cloneObject(
        _.find(this.dataService.equipments, (e) => e.name == selectedItem.value)
      );
      this.planogram.equipmentId = this.currentEquipment.id;
      this.planogram.structure = undefined;
      this.showEquipment();
    })
  }

  removeShelfProduct(catalogId: string) {
    this.planogramBuilder.removeAreaProduct(catalogId)
  }

  async downloadImageImportedFromUrl(imageLink: string) {
    const image = await fetch(imageLink)
    const imageBlob = await image.blob()
    const imageUrl = URL.createObjectURL(imageBlob)


    const ext = imageLink.split('?').shift().split('.').pop()
    const $a = document.createElement('a')
    $a.href = imageUrl
    $a.download = this.planogram.name + '.' + ext
    document.body.appendChild($a)
    $a.click()
    document.body.removeChild($a)
  }

  async downloadPlanogramImage() {
    const aElement = await this.planogramBuilder.createPlanogramImage();
    document.body.appendChild(aElement);
    aElement.click();
    document.body.removeChild(aElement);
  }

  async saveAsDraft() {
    this.planogram.state = PLANOGRAM_STATUS.DRAFT;
    const aElement = await this.planogramBuilder.createPlanogramImage();
    const planogramImage = this.utilitiesService.dataUrlToFile(
      aElement.dataset.downloadurl,
      this.planogram.name +
      "_" +
      this.datepipe.transform(new Date(), "yyyy-MM-dd-HH-mm-ss") +
      ".png"
    );
    const imgUrl =
      await this.appService.uploadFileToStorageAndCompressIfNeedded(
        planogramImage
      );
    this.planogram.structure = this.planogramBuilder.preparePlanogramStructure()
    await this.dal.updatePlanogram(this.planogram, imgUrl);
    this.snackbarService.openSnackBar(
      2000,
      this.languageService.translateSync("PlanogramSavedSuccessfully")
    );
    this.router.navigateByUrl("/planograms");
  }

  async saveAsPublish() {
    const aElement = await this.planogramBuilder.createPlanogramImage();
    const planogramImage = this.utilitiesService.dataUrlToFile(
      aElement.dataset.downloadurl,
      this.planogram.name +
      "_" +
      this.datepipe.transform(new Date(), "yyyy-MM-dd-HH-mm-ss") +
      ".png"
    );
    const imgUrl = await this.appService.uploadFileToStorageAndCompressIfNeedded(planogramImage);
    this.snackbarService.openSnackBar(2000, this.languageService.translateSync("UpdatingPlanogram"));
    this.planogram.structure = this.planogramBuilder.preparePlanogramStructure()
    await this.dal.updatePlanogram(this.planogram, imgUrl)
    this.snackbarService.openSnackBar(2000, this.languageService.translateSync('PublishingPlanogram'))
    await this.dal.publishPlanogram(this.planogram.id, this.planogram.mc1PlanogramId)
    this.planogram.state = PLANOGRAM_STATUS.PUBLISHED;
    this.snackbarService.openSnackBar(2000, this.languageService.translateSync('PlanogramSavingProcessWasSuccessful'))
    this.router.navigateByUrl('/planograms');
  }

  getEquipmentSelectPlaceholder() {
    if (!this.currentEquipment) {
      return this.languageService.translateSync("EquipmentType");
    } else {
      return "";
    }
  }

  editEquipment() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        message: this.languageService.translateSync(
          "EditEquipmentActionResetsThePlanogramWhetherToContinue"
        ),
      },
    });
    dialogRef.afterClosed().subscribe((confirmResult) => {
      if (!confirmResult) {
        return;
      }
      this.showEquipment();
      this.planogram.structure = undefined;
    });
  }

  removeEquipment() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        message: this.languageService.translateSync(
          "TheEquipmentWillBeDeletedAreYouSure"
        ),
      },
    });

    dialogRef.afterClosed().subscribe((confirmResult) => {
      if (!confirmResult) {
        return;
      }
      let currentEquipment = this.currentEquipment;
      let indexFound = _.findIndex(
        this.dataService.equipments,
        (e) => e.id == currentEquipment.id
      );
      if (indexFound != -1) {
        this.dataService.equipments.splice(indexFound, 1);
      }
      this.currentEquipment = null;
    });
  }

  productsAddFilter(event: MatChipInputEvent) {
    const input = event.input;
    const value = event.value;

    if ((value || "").trim()) {
      this.productsFilters.push(value.trim());
      this.searchProducts(true);
    }

    // Reset the input value
    if (input) {
      input.value = "";
    }
  }
  productsRemoveFilter(filter: string) {
    const index = this.productsFilters.indexOf(filter);

    if (index >= 0) {
      this.productsFilters.splice(index, 1);
      this.searchProducts(true);
    }
  }

  async shelfProductsAddFilter(event: MatChipInputEvent) {
    const input = event.input;
    const value = event.value;

    if ((value || "").trim()) {
      this.productsShelfFilters.push(value.trim());
      this.filterShelfProducts();
    }

    // Reset the input value
    if (input) {
      input.value = "";
    }
  }

  shelfProductsRemoveFilter(filter: string) {
    const index = this.productsShelfFilters.indexOf(filter);

    if (index >= 0) {
      this.productsShelfFilters.splice(index, 1);
      this.filterShelfProducts();
    }
  }
  async filterShelfProducts() {
    if (!this.selectedShelfProducts) return;

    const noFilters = Object.values(this.modalFiltersShelf).every(value => value === null || value === undefined || value.length === 0 || value === false);
    if (this.productsShelfFilters.length === 0 && noFilters) {
      this.filteredShelfProducts = this.selectedShelfProducts
      return;
    }

    const keys = Object.keys(this.selectedShelfProducts);
    const selectedShelfProductsIds = keys.map((v) => this.selectedShelfProducts[v].id).filter((productId) => productId !== undefined);

    let shelfProducts = await this.dal.searchProductsByIds(
      selectedShelfProductsIds
    );

    let filteredShelfProductsIds: string[]

    if (this.modalFiltersShelf !== null) {
      filteredShelfProductsIds = shelfProducts.items.filter(product => this.itemMatchesFilters(product, this.modalFiltersShelf)).map(product => product.productId)
    }

    const filteredSelectedShelfProducts = {}

    for (const key in this.selectedShelfProducts) {
      const product = this.selectedShelfProducts[key];

      const productNameLower = product.name.toLowerCase();
      const nameFilterMatch = this.productsShelfFilters && this.productsShelfFilters.some(f => productNameLower.includes(f.toLowerCase()));

      const idFilterMatch = filteredShelfProductsIds && filteredShelfProductsIds.includes(product.id);

      const genCondition =
        (nameFilterMatch && idFilterMatch) ||
        (this.productsShelfFilters.length === 0 && idFilterMatch) ||
        (this.filterShelfProducts.length === 0 && nameFilterMatch) // testar

      if (genCondition) {
        filteredSelectedShelfProducts[key] = product;
      }
    }

    this.filteredShelfProducts = filteredSelectedShelfProducts
  }

  itemMatchesFilters(item, filters: SearchProductsRequestFiltersPlanogram) {
    if (filters.brands && filters.brands.length > 0) {
      if (!item.brand) return false;
      if (!filters.brands.includes(item.brand) && item.brand) return false;
    }

    if (filters.categories) {
      if (!item.category) return false;
      if (!filters.categories.includes(item.category) && item.category) return false;
    }

    if (filters.isCompetitor !== null) {
      const isCompetitor = filters.isCompetitor === 'true' ? true : false
      if (item.isCompetitors !== isCompetitor) return false;
    }

    return true;
  }

  displayPlanogramType(planogramType: string) {
    if (planogramType.toLowerCase() === "planogram") {
      return "Fixed";
    }
    if (planogramType.toLowerCase() === "free") {
      return "Free";
    }
    return ""
  }

  zoomIn() {
    this.zoom += 0.1;
    this.zoom = this.zoom > 2 ? 2 : this.zoom
  }

  zoomOut() {
    const newZoom = this.zoom - 0.1;
    this.zoom = newZoom > 0.5 ? newZoom : 0.5
  }

  openImportFromImageDialog() {
    const dialogRef = this.dialog.open(ImportFromImageDialogComponent, {
      width: "900px",
      data: {
        planogramId: this.planogram.id,
        equipments: this.dataService.equipments
      },
    });
    dialogRef.afterClosed().subscribe((data: { equipment: EquipmentWithId, structure: Structure, catalogs: ProductWithCatalog[], imageImportedFromUrl: string }) => {
      if (!data) return;
      this.currentEquipment = data.equipment
      this.planogram.equipmentId = this.currentEquipment.id
      this.planogram.structure = data.structure
      this.planogram.products = data.catalogs
      this.planogram.imageImportedFromUrl = data.imageImportedFromUrl
      this.showEquipment()
    });
  }
}
