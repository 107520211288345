<div [hidden]="!showSpinnerProducts" class="loading-indicator spinner-products">
  <mat-progress-spinner [hidden]="!showSpinnerProducts" mode="indeterminate"></mat-progress-spinner>
</div>
<div class="planogram-editor-container colored-background" [hidden]="!planogram">
  <div class="planogram-editor-main-content-container">
    <div class="planogram-editor-products-col left-col">
      <div class="card">
        <div class="planogram-title-header">
          <button mat-icon-button routerLink="/planograms">
            <mat-icon color="primary">arrow_back</mat-icon>
          </button>
          <div *ngIf="planogram">
            <div class="planogram-title">
              {{ "Planogram" | translate }}
            </div>
            <div>
              <h5>{{ planogram.name }}</h5>
            </div>
          </div>
          <div class="ml-auto">
            <button mat-icon-button color="primary" (click)="isPlanogramInfoOpen = !isPlanogramInfoOpen">
              <mat-icon>info</mat-icon>
            </button>

            <div *ngIf="isPlanogramInfoOpen" class="info-card planogram-info-card">
              <div class="planogram-info-card-item">
                <span>{{ "Planogram" | translate }}</span>
                <p>{{ planogram.name }}</p>
              </div>
              <div class="planogram-info-card-item">
                <span>{{ "PlanogramID" | translate }}</span>
                <p>{{ planogram.id }}</p>
              </div>
              <div class="planogram-info-card-item">
                <span>{{ "PlanogramType" | translate }}</span>
                <p>{{ planogram.planogramType | titlecase }}</p>
              </div>
              <div class="planogram-info-card-item">
                <span>{{ "shareOfShelfType" | translate }}</span>
                <p>{{ planogram.shareOfShelfType | titlecase }}</p>
              </div>
              <div class="planogram-info-card-item" *ngIf="planogram?.imageImportedFromUrl">
                <span>{{ "planogramBaseModel" | translate }}</span>
                <button class="link-button" (click)="downloadImageImportedFromUrl(planogram.imageImportedFromUrl)">{{
                  "ClickToDownload" | translate }}
                  <!-- <mat-icon style="margin-bottom: -8px;">download</mat-icon> -->
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="header-import-btn">
          <button mat-flat-button color="primary" (click)="openImportFromImageDialog()" *ngIf="!planogram?.equipmentId">
            <span class="edit-button-content">
              <mat-icon>upload</mat-icon>
              {{ "ImportPlanogram" | translate }}
            </span>
          </button>
        </div>
      </div>
      <div class="card">
        <div class="equipment-header">
          <h5>{{ "Equipment" | translate }}</h5>
          <button mat-button color="primary" (click)="createEquipment()" style="padding: 0 !important">
            {{ "ADdNEwEQUIPMENT" | translate }}
          </button>
        </div>
        <div style="
            display: flex;
            align-items: center;
            gap: 16px;
            align-self: stretch;
          ">
          <mat-form-field *ngIf="dataService.equipments.length > 0">
            <mat-select *ngIf="currentEquipment" placeholder="{{ getEquipmentSelectPlaceholder() }}"
              [(ngModel)]="currentEquipment.name" (selectionChange)="equipmentSelected($event)">
              <mat-option *ngFor="let equipment of dataService.equipments" [value]="equipment.name">{{ equipment.name }}
              </mat-option>
            </mat-select>
            <mat-select *ngIf="!currentEquipment" placeholder="{{ getEquipmentSelectPlaceholder() }}"
              (selectionChange)="equipmentSelected($event)">
              <mat-option *ngFor="let equipment of dataService.equipments" [value]="equipment.name">{{ equipment.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <button mat-icon-button color="primary" (click)="getEquipmentsPromise()">
            <mat-icon>refresh</mat-icon>
          </button>
        </div>
      </div>

      <div *ngIf="currentEquipment" class="planogram-editor-items card">
        <div>
          <mat-panel-title>
            <h5>{{ "Planogram" | translate }}</h5>
          </mat-panel-title>
        </div>

        <div class="planogram-editor-item">
          <h6>{{ "EmptySpace" | translate }}</h6>
          <div class="planogram-editor-product-quantity-input-container">
            <input hidden id="emptySpaceColor" [value]="emptySpaceColor" />
            <mat-form-field>
              <input type="number" id='emptySpaceWidth' [(ngModel)]="emptySpaceWidth" matInput
                placeholder="{{ 'Width' | translate }} (cm)" />
            </mat-form-field>
            <mat-form-field>
              <input type="number" id="emptySpaceHeight" [(ngModel)]="emptySpaceHeight" matInput
                placeholder="{{ 'Height' | translate }} (cm)" />
            </mat-form-field>
          </div>
          <div id="empty-space-drag" class="planogram-item-drag">
            <div class="planogram-item-squere" [ngStyle]="{backgroundColor: emptySpaceColor}"></div>
            <mat-icon matTooltip="{{ 'DragMe' | translate }}" style="min-width: 19.5px">drag_indicator</mat-icon>
          </div>

        </div>

        <div *ngIf="isAllowCategories && currentEquipment">
          <div class="planogram-editor-item">
            <h6>{{ "Category" | translate }}</h6>
            <div class="planogram-editor-product-quantity-input-container">
              <input hidden id="categoryId" [value]="category?.id ?? ''" />
              <input hidden id="categoryName" [value]="category?.name ?? ''" />
              <input hidden id="categoryColor" [value]="categoryColor" />
              <mat-form-field>
                <input type="number" id="categoryWidth" [(ngModel)]="categorySize" matInput
                  placeholder="{{ 'Size' | translate }} (cm)" />
              </mat-form-field>
              <mat-form-field>
                <mat-select [(value)]="category" placeholder="{{ 'Category' | translate }}">
                  <mat-option *ngFor="let category of dataService.categoriesWithIds" [value]="category">{{
                    category.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div id="planogram-editor-category" class="planogram-item-drag">
              <div class="planogram-item-squere" [ngStyle]="{backgroundColor: categoryColor}"></div>
              <mat-icon matTooltip="{{ 'DragMe' | translate }}" style="min-width: 19.5px">drag_indicator</mat-icon>
            </div>
          </div>
        </div>

        <div *ngIf="isAllowBrands && currentEquipment">
          <div class="planogram-editor-item">
            <h6>{{ "Brand" | translate }}</h6>
            <div class="planogram-editor-product-quantity-input-container">
              <input hidden id="brandId" [value]="brand?.id ?? ''" />
              <input hidden id="brandName" [value]="brand?.name ?? ''" />
              <input hidden id="brandColor" [value]="brandColor" />
              <mat-form-field>
                <input type="number" id="brandWidth" [(ngModel)]="brandSize" matInput
                  placeholder="{{ 'Size' | translate }} (cm)" />
              </mat-form-field>
              <mat-form-field>
                <mat-select [(value)]="brand" placeholder="{{ 'Brand' | translate }}">
                  <mat-option *ngFor="let brand of dataService.brandsWithIds" [value]="brand">{{ brand.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div id="planogram-editor-brand" class="planogram-item-drag">
              <div class="planogram-item-squere" [ngStyle]="{backgroundColor: brandColor}"></div>
              <mat-icon matTooltip="{{ 'DragMe' | translate }}" style="min-width: 19.5px">drag_indicator</mat-icon>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="planogram-editor-gondola-col" id="planogram-editor-gondola-col">
      <div class="planogram-editor-section-header">
        <button mat-stroked-button color="primary" routerLink="/home"
          style="background: rgba(51, 100, 162, 0.16) !important">
          <span class="edit-button-content">
            <mat-icon>home</mat-icon>
            <span>{{ "Home" | translate | uppercase }}</span>
          </span>
        </button>
        <div class="edit-buttons-container">
          <button class="md-small" mat-flat-button color="primary" [disabled]="!currentEquipment"
            (click)="downloadPlanogramImage()">
            <span class="edit-button-content">
              <mat-icon>image</mat-icon>
              {{ "DownloadPng" | translate | uppercase }}
            </span>
          </button>

          <button mat-flat-button color="primary" [disabled]="!currentEquipment" (click)="saveAsDraft()">
            <span class="edit-button-content">
              <mat-icon>cloud_queue</mat-icon>
              {{ "saveASdraft" | translate }}
            </span>
          </button>

          <button mat-flat-button color="primary" [disabled]="!currentEquipment" (click)="saveAsPublish()">
            <span class="edit-button-content">
              <mat-icon>cloud_upload</mat-icon>
              {{ "saveAndpublish" | translate }}
            </span>
          </button>
        </div>
      </div>
      <div id="planogramEditorGondolaContentContainer" class="planogram-editor-gondola-content-container"
        [ngClass]="{'center-content':currentEquipment ? false : true}">
        <div class="planogram-editor-new-aquipment-button-container" [hidden]="currentEquipment">
          <button mat-flat-button color="primary" (click)="createEquipment()">
            {{ "ADdNEwEQUIPMENT" | translate }}
          </button>
          <div class="planogram-editor-new-aquipment-button-text">
            {{ "ChooseAnEquipmentToStart" | translate }}
          </div>
        </div>
        <div *ngIf="currentEquipment" class="flex zoom-buttons-container">
          <div class="flex-1">
            <button mat-mini-fab color="primary" (click)="zoomOut()">
              <mat-icon>remove</mat-icon>
            </button>
          </div>
          <div class="flex-1">
            <button mat-mini-fab color="primary" (click)="zoomIn()">
              <mat-icon>add</mat-icon>
            </button>
          </div>
        </div>
        <planogram-builder #planogramBuilder *ngIf="currentEquipment" [height]="equipmentHeight"
          [width]="equipmentWidth" [zoom]="zoom" [InitialPixelHeight]="builderHeight" [InitialPixelWidth]="builderWidth"
          [equipment]="currentEquipment" [planogram]="planogram" [emptySpaceColor]="emptySpaceColor"
          [categoryColor]="categoryColor" [brandColor]="brandColor"
          (onAreaChanged)="areaChanged($event)"></planogram-builder>
      </div>
    </div>

    <div class="planogram-editor-products-col" *ngIf="currentEquipment">
      <mat-tab-group #productTabs mat-stretch-tabs="true" mat-align-tabs="start" animationDuration="500ms">
        <mat-tab label="{{ 'AllCatalogs' | translate }}">
          <ng-template matTabContent>
            <div class="product-list-card">
              <div class="planogram-editor-products-search-container">
                <div class="planogram-editor-products-search-input-container">
                  <div class="search-button-container">
                    <mat-form-field class="aligned" appearance="fill">
                      <mat-icon color="primary" matPrefix>search</mat-icon>
                      <mat-chip-list #filterChipList>
                        <mat-chip *ngFor="let filter of productsFilters" [selectable]="selectable" [removable]="true"
                          (removed)="productsRemoveFilter(filter)">
                          {{ filter }}
                          <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                        <input class="border-radius-15" style="font-size: 14px; margin-left: 2px;"
                          placeholder="{{ 'SearchProduct' | translate }}" [matChipInputFor]="filterChipList"
                          [matChipInputSeparatorKeyCodes]="
                            productsFiltersSeparatorKeysCodes
                          " [matChipInputAddOnBlur]="true" (matChipInputTokenEnd)="productsAddFilter($event)" />
                      </mat-chip-list>
                      <mat-icon matSuffix *ngIf="productsFiltersShowSpinner">
                        <i class="fa fa-spinner fa-spin"></i>
                      </mat-icon>
                    </mat-form-field>
                  </div>

                  <div class="filter-button-container">
                    <button mat-icon-button color="primary" [style]="{ background: '#E0E9F5' }"
                      (click)="openFilterOptions($event)">
                      <mat-label>{{ 'Filters' | translate | uppercase }}</mat-label>
                      <mat-icon>tune</mat-icon>
                    </button>
                  </div>
                </div>
              </div>

              <div class="planogram-editor-products-list-container">
                <div class="planogram-editor-product-item" *ngFor="let product of products; let i = index">
                  <!-- selector-product class is for js, DO NOT REMOVE -->
                  <div class="planogram-editor-product-image-container selector-product">
                    <div hidden class="hiddenData">
                      {{product | json}}
                    </div>
                    <div class="product-image-sub-container">
                      <mat-icon matTooltip="{{ 'DragMe' | translate }}"
                        style="min-width: 19.5px">drag_indicator</mat-icon>
                      <div (mouseover)="this.expandedImage[product.thumbnailUrl] = true"
                        (mouseleave)="this.expandedImage[product.thumbnailUrl] = false">
                        <img [src]="product.thumbnailUrl" alt="product image" class="img-product-list" [style]="{
                            filter: this.expandedImage[product.thumbnailUrl] === true ? 'grayscale(80%)' : 'none'
                          }" matTooltip="width: {{
                            product.width
                          }}, height: {{ product.height }}" />
                      </div>

                      <div *ngIf="expandedImage[product.thumbnailUrl] === true && product?.thumbnailUrl"
                        class="expanded-product-image">
                        <img [src]="product.thumbnailUrl" alt="product image" [style]="{
                              maxHeight: '120px'
                            }" matTooltip="width: {{
                              product.width
                            }}, height: {{ product.height }}" />
                      </div>
                      <div>
                        <div class="product-name" matTooltip="{{ product.name }}">
                          {{ product.name }}
                        </div>
                        <div class="product-id" matToolTip="{{ product.id }}">
                          {{ product.catalogName }}
                        </div>
                        <div class="product-ean-span" *ngIf="product.ean" matToolTip="{{ product.ean }}">
                          <span> <b>{{ 'EAN' | translate }}:</b> {{ product.ean }} </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="planogram-editor-product-quantity-input-container">
                    <mat-form-field style="width: 100%">
                      <input class="faces-input" type="number" [(ngModel)]="product.quantity" matInput
                        placeholder="{{ 'NumberOfFaces' | translate }}" min="1" />
                    </mat-form-field>
                  </div>
                </div>
                <mat-paginator [length]="totalProducts" [pageSizeOptions]="[5, 10, 25, 100]"
                  (page)="searchProducts(false)" [pageSize]="25">
                </mat-paginator>
              </div>
            </div>
          </ng-template>
        </mat-tab>
        <mat-tab label="{{ 'ShelfProducts' | translate }}{{selectedShelfProductsCount}}">
          <ng-template matTabContent>
            <div class="product-list-card">
              <div class="planogram-editor-products-search-container">
                <div class="planogram-editor-products-search-input-container">
                  <div class="search-button-container">
                    <mat-form-field class="aligned" appearance="fill">
                      <mat-icon color="primary" matPrefix>search</mat-icon>
                      <mat-chip-list #shelfFilterChipList>
                        <mat-chip *ngFor="let filter of productsShelfFilters" [selectable]="selectable"
                          [removable]="true" (removed)="shelfProductsRemoveFilter(filter)">
                          {{ filter }}
                          <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                        <input class="border-radius-15" style="font-size: 14px; margin-left: 2px;"
                          placeholder="{{ 'SearchProduct' | translate }}" [matChipInputFor]="shelfFilterChipList"
                          [matChipInputSeparatorKeyCodes]="
                            productsFiltersSeparatorKeysCodes
                          " [matChipInputAddOnBlur]="true" (matChipInputTokenEnd)="shelfProductsAddFilter($event)" />
                      </mat-chip-list>
                      <mat-icon matSuffix *ngIf="productsFiltersShowSpinner">
                        <i class="fa fa-spinner fa-spin"></i>
                      </mat-icon>
                    </mat-form-field>
                  </div>

                  <div class="filter-button-container">
                    <button mat-icon-button color="primary" [style]="{ background: '#E0E9F5' }"
                      (click)="openShelfFilterOptions($event)">
                      <mat-label>{{ 'Filters' | translate | uppercase }}</mat-label>
                      <mat-icon>tune</mat-icon>
                    </button>
                  </div>
                </div>
              </div>

              <div class="planogram-editor-products-list-container">
                <div class="planogram-editor-product-item shelf-products"
                  *ngFor="let product of filteredShelfProducts | keyvalue; trackBy: key">
                  <div class="planogram-editor-product-image-container selector-product">
                    <div hidden class="hiddenData">
                      {{product.value | json}}
                    </div>
                    <div class="product-image-sub-container">
                      <mat-icon matTooltip="{{ 'DragMe' | translate }}"
                        style="min-width: 19.5px">drag_indicator</mat-icon>
                      <img [src]="product.value.thumbnailUrl" alt="product image" class="img-product-list" matTooltip="width: {{
                          product.value.width
                        }}, height: {{ product.value.height }}" />
                      <!-- <div class="planogram-editor-product-image"
                        matTooltip="{{'Width'|translate}}: {{product.value.rect.width}}, {{'Height'|translate}}: {{product.value.rect.height}}"
                        [ngStyle]="{backgroundImage: 'url(' + product.value.imgUrl + ')'}">
                      </div> -->
                      <div>
                        <div>
                          <div class="product-name" matTooltip="{{ product.value.name }}">
                            {{ product.value.name }}
                          </div>
                          <div class="product-id" matToolTip="{{ product.value.id }}">
                            {{ product.value.catalogName }}
                          </div>
                          <div class="product-ean-span" *ngIf="product.value.ean" matToolTip="{{ product.value.ean }}">
                            <span> <b>{{ 'EAN' | translate }}:</b> {{ product.value.ean }} </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="planogram-editor-product-shelf-quantity-remove-container">
                    <div class="planogram-editor-product-shelf-text flex-1">
                      <span><b>{{ 'NumberOfFaces' | translate }}</b></span>
                      <span [style]="{ color: '#6B6B6B' }">
                        {{ product.value.quantity }}
                      </span>
                    </div>
                    <div class="planogram-editor-product-item-bottom-buttons-container flex-1">
                      <button mat-button color="warn" (click)="removeShelfProduct(product.value.catalogId)">
                        <span class="edit-button-content">
                          <!-- <mat-icon>delete</mat-icon> -->
                          {{ "RemoveAll" | translate | uppercase }}
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>