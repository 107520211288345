import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { fromEvent } from 'rxjs';
import { filter, finalize, switchMap, takeUntil, tap } from 'rxjs/operators';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { EquipmentWithId, ShelfWebStructure } from 'src/app/shared/api-structures/misc/equipment';
import { PlanogramWithId } from 'src/app/shared/api-structures/misc/planogram';
import { AutoDestroy } from 'src/app/shared/base-directives/auto-destroy';
import { Rect } from './Rect';
import { findOverlapItems, getMidItemPos, getNearRect, isAboveItem, isRectOutsideArea, roundNumber, createTempId, prepareStructureItem, prepareStructureArea } from './builderHelper'
import { Area, Item, Structure } from 'src/app/shared/api-structures/misc/planogramStructure';
import { AreaData, BrandData, CatalogData, CategoryData } from '../planogram-planning/planogram-planning.component';
import { LanguageService } from 'src/app/shared/services/language.service';
import { SnackbarService } from 'src/app/services/snackbar.service';

export type BuilderItem = {
  id: string;
  rect: Rect;
  area: string;
  background?: string;
  imgSrc?: string;
  draggable: boolean;
  text?: string;
  style?: any;
  autoHeight: boolean;
} & (
    {
      type: 'product';
      data: CatalogData;
    } |
    {
      type: 'area'
      data: AreaData
    } |
    {
      type: 'category'
      data: CategoryData
    } |
    {
      type: 'seperator'
      data: AreaData
    } |
    {
      type: 'space'
    } |
    {
      type: 'brand'
      data: BrandData
    }
  )


export type Pos = {
  x: number;
  y: number;
}

export type MousePos = {
  pixelX: number;
  pixelY: number;
  x: number;
  y: number;
}

export type BuilderArea = {
  id: string;
  rect: Rect;
  products: CatalogData[]
}

export type DragData = {
  lastMousePos: MousePos
  target: HTMLElement
  itemCopy: BuilderItem
  itemHasRoom: boolean
  quantity: number
  type: 'existingItem' | 'newItem' | ''
}

const shadowColor = 'rgba(11, 87, 208, 0.3)'
const shadowColorRed = 'rgba(208, 11, 11, 0.3)'

@Component({
  selector: 'planogram-builder',
  templateUrl: './planogram-builder.component.html',
  styleUrls: ['./planogram-builder.component.scss']
})
export class PlanogramBuilderComponent extends AutoDestroy {

  constructor(public utils: UtilitiesService, public snackbarService: SnackbarService, public languageService: LanguageService) {
    super()
  }
  @ViewChild('planogramBuilder') planogramBuilder: ElementRef<HTMLElement>;
  @ViewChild('canvas') canvas: ElementRef<HTMLCanvasElement>;

  @Input() height = 0;
  @Input() width = 0;
  @Input() InitialPixelHeight = 0;
  @Input() InitialPixelWidth = 0;
  @Input() planogram: PlanogramWithId
  @Input() equipment: EquipmentWithId
  @Input() emptySpaceColor
  @Input() brandColor
  @Input() categoryColor
  _zoom = 1
  @Input() set zoom(val: number) {
    this._zoom = val
    this.init()
  }
  get zoom() {
    return this._zoom
  }

  @Output() onAreaChanged = new EventEmitter<{ area: BuilderArea, quiet?: boolean }>()

  // shadowItem: BuilderItem = null
  pixelHeight = 0;
  pixelWidth = 0;
  items: BuilderItem[] = []
  shelves: BuilderItem[] = []
  itemHasRoom = false
  selectedAreaId = ''
  clickTimer: NodeJS.Timeout = null
  isClick = false
  // productsData: { [key: string]: CatalogData } = {}
  otherItemsData: { [key: string]: CatalogData } = {}
  tempItemData: CatalogData = null
  shadowItem: BuilderItem = null
  draggedItem: BuilderItem = null
  isTempItem = false
  dragData: DragData
  absoluteMouse: [number, number] = [0, 0]
  loaded = false


  ngAfterContentInit() {
    this.loaded = true
    this.pixelHeight = this.InitialPixelHeight * this.zoom
    this.pixelWidth = this.InitialPixelWidth * this.zoom
    this.createAreasList()
    this.createShelfItemsList()
    this.init()
  }

  ngAfterViewInit() {
    this.registerDrag()
  }

  init() {
    if (!this.loaded) return
    this.pixelHeight = this.InitialPixelHeight * this.zoom
    this.pixelWidth = this.InitialPixelWidth * this.zoom
    this.setupAreas()
    this.setupShelfItems()
  }

  selectAreaId(id: string) {
    if (this.selectedAreaId === id) {
      return
    }
    this.selectedAreaId = id
    this.areaChanged(id)
  }

  areaChanged(id, quiet = false) {
    const a = this.shelves.find(v => v.id === id)
    const products: CatalogData[] = []
    this.items.forEach(v => {
      if (v.type === 'product' && v.area === id) {
        products.push(v.data)
      }
    })
    const area: BuilderArea = {
      id,
      rect: a.rect,
      products: products,
    }

    this.onAreaChanged.emit({ area, quiet })
  }

  addAreaHorizontal(shelf: ShelfWebStructure, withSeparator = false) {
    const shelfHeight = shelf.shelfHeight || this.equipment.shelfHeight
    const shelfY = this.shelves.reduce((acc, curr) => acc + Number(curr.rect.height), 0)
    const areaId = createTempId()
    const item: BuilderItem = {
      id: areaId,
      area: areaId,
      rect: new Rect(0, shelfY, this.width, shelfHeight),
      type: 'area',
      draggable: false,
      autoHeight: false,
      data: {
        areaType: 'horizontal'
      }
    }

    this.shelves.push(item)

    if (withSeparator) {
      const separatorHeight = shelf.shelfSeparatorHeight || this.equipment.shelfSeparatorHeight || this.utils.percentOf(0, this.pixelHeight)
      const separatorY = this.shelves.reduce((acc, curr) => acc + curr.rect.height, 0)

      const item: BuilderItem = {
        id: createTempId(),
        area: areaId,
        rect: new Rect(0, separatorY, this.width, separatorHeight),
        type: 'seperator',
        background: 'black',
        draggable: false,
        style: {
          borderBottom: '2px solid black'
        },
        autoHeight: false,
        data: {
          areaType: 'seperator'
        }
      }

      this.shelves.push(item)
    }
  }

  addAreaVertical(shelf: ShelfWebStructure, withSeparator = false) {
    const shelfWidth = shelf.shelfHeight || this.equipment.shelfHeight
    const shelfX = this.shelves.reduce((acc, curr) => acc + curr.rect.width, 0)
    const areaId = createTempId()
    const item: BuilderItem = {
      id: areaId,
      area: areaId,
      rect: new Rect(shelfX, 0, shelfWidth, this.height),
      type: 'area',
      draggable: false,
      autoHeight: false,
      data: {
        areaType: 'vertical'
      }
    }

    this.shelves.push(item)

    if (withSeparator) {
      const separatorWidth = shelf.shelfSeparatorHeight || this.equipment.shelfSeparatorHeight || this.utils.percentOf(0, this.pixelHeight)
      const separatorX = this.shelves.reduce((acc, curr) => acc + curr.rect.width, 0)

      const item: BuilderItem = {
        id: createTempId(),
        area: areaId,
        rect: new Rect(separatorX, 0, separatorWidth, this.height),
        type: 'seperator',
        background: 'black',
        draggable: false,
        autoHeight: false,
        style: {
          borderBottom: '2px solid black'
        },
        data: {
          areaType: 'seperator'
        }
      }

      this.shelves.push(item)
    }
  }

  removeAreaProduct(catalogId: string) {
    const itemsCopy = this.items
    this.items = null
    setTimeout(() => {
      this.items = itemsCopy.filter(v => v.area !== this.selectedAreaId || v.type !== 'product' || v.data.catalogId !== catalogId)
      this.areaChanged(this.selectedAreaId, true)
    })
  }

  setupItem(item: BuilderItem) {
    if (!item.style) item.style = {}

    const heightP = this.utils.percentOf(item.rect.height, this.height)
    const widthP = this.utils.percentOf(item.rect.width, this.width)

    item.style.height = `${(heightP / 100) * this.pixelHeight}px`
    item.style.width = `${(widthP / 100) * this.pixelWidth}px`

    if (item.style?.backgroundColor !== item.background) {
      item.style.backgroundColor = item.background
    }

    const imgSrc = item.imgSrc ? `url(${item.imgSrc})` : ''
    if (item.style?.backgroundImage !== imgSrc) {
      item.style.backgroundImage = imgSrc
    }

    if (item.draggable && !item.style.cursor) {
      item.style.cursor = 'grab'
    }

    this.setPosition(item)

    return item
  }

  setPosition(item: BuilderItem, domItem?: HTMLElement) {
    const topP = this.utils.percentOf(item.rect.y, this.height)
    const leftP = this.utils.percentOf(item.rect.x, this.width)
    const widthP = this.utils.percentOf(item.rect.width, this.width)
    const heightP = this.utils.percentOf(item.rect.height, this.height)

    item.style.top = `${topP}%`
    item.style.left = `${leftP}%`

    item.style.height = `${(heightP / 100) * this.pixelHeight}px`
    item.style.width = `${(widthP / 100) * this.pixelWidth}px`
    if (domItem) {
      domItem.style.top = item.style.top
      domItem.style.left = item.style.left
      domItem.style.width = item.style.width
      domItem.style.height = item.style.height
    }
  }

  //setAbsolutePosition(item:BuilderItem)

  createAreasList() {
    this.shelves = []
    let shelves: ShelfWebStructure[] = this.equipment?.shelves
    if (!shelves) {
      shelves = []
      for (let i = 0; i < this.equipment.numberOfShelves; i++) {
        shelves.push({
          shelfHeight: this.equipment.shelfHeight,
          depth: this.equipment.depth,
          shelfSeparatorHeight: this.equipment.shelfSeparatorHeight,
        })
      }
    }

    for (let i = 0; i < shelves?.length || 0; i++) {
      if (this.equipment.isVertical) {
        this.addAreaVertical(shelves[i], i < shelves.length - 1)
      } else {
        this.addAreaHorizontal(shelves[i], i < shelves.length - 1)
      }
    }
  }

  setupAreas() {
    const shelves = this.shelves.map(v => this.setupItem(v))
    this.shelves = null
    setTimeout(() => {
      this.shelves = shelves
    })
  }


  createShelfItemsList() {
    this.items = []
    this.planogram?.structure?.areas?.forEach((area) => {
      area.items?.forEach(v => {
        if (!v.rect) return
        const rect = new Rect(v.rect.x + area.rect.x, v.rect.y + area.rect.y, v.rect.width, v.rect.height)
        const builderArea = this.findAreaInPos(rect)
        let item = null
        if (v.itemType === 'space') {
          item = {
            id: createTempId(),
            area: builderArea?.id,
            rect: rect,
            type: 'space',
            draggable: true,
            background: this.emptySpaceColor,
            autoHeight: false,
          }
        } else if (v.itemType === 'category') {
          item = {
            id: createTempId(),
            area: builderArea?.id,
            rect: rect,
            type: 'category',
            background: this.categoryColor,
            text: v.name,
            draggable: true,
            autoHeight: false,
            data: {
              id: v.categoryId || '',
              name: v.name,
            }
          }
        } else if (v.itemType === 'brand') {
          item = {
            id: createTempId(),
            area: builderArea?.id,
            rect: rect,
            type: 'brand',
            background: this.brandColor,
            text: v.name,
            draggable: true,
            autoHeight: false,
            data: {
              id: v.brandId || '',
              name: v.name,
            }
          }
        } else if (v.itemType === 'product') {

          // const planogramProduct = this.planogram.products?.find(p => p.id === v.productId)
          // const productCatalog = planogramProduct?.imageCatalogs.find(p => p.id === v.imagesCatalogId)
          let planogramItem: Item
          this.planogram.structure?.areas?.find(a => {
            const p = a.items?.find(i => i.productId === v.productId)
            if (p) {
              planogramItem = p
              return true
            }
          })
          const product = this.planogram.products?.find(p => p.id === v.productId)
          const catalogData = {
            id: v.productId,
            name: planogramItem?.name,
            lastUpdateCatalog: v.lastUpdadeCatalog,
            isTrained: v.isTrained,
            thumbnailUrl: v.imgUrl,
            catalogId: v.imagesCatalogId,
            width: planogramItem?.rect?.width,
            height: planogramItem?.rect?.height,
            catalogName: product?.imageCatalogs[0]?.name,
            ean: v.ean,
            quantity: 1,
          }

          item = this.setupItem({
            id: createTempId(),
            area: builderArea?.id,
            rect: rect,
            type: 'product',
            imgSrc: v.imgUrl,
            draggable: true,
            autoHeight: false,
            data: catalogData
          })
        }
        if (item) {
          this.items.push(item)
        }

      })
    })
  }

  setupShelfItems() {
    const items = this.items.map(v => this.setupItem(v))
    this.items = null
    setTimeout(() => {
      this.items = items
    })
  }

  calcRelativeMouse(e: MouseEvent) {
    const rect = this.planogramBuilder.nativeElement.getBoundingClientRect()

    const pixelX = e.clientX - rect.left
    const pixelY = e.clientY - rect.top

    const x = (pixelX / this.pixelWidth) * this.width
    const y = (pixelY / this.pixelHeight) * this.height
    this.absoluteMouse = [e.clientX, e.clientY]
    return { pixelX, pixelY, x, y }
  }

  findAreaInPos(rect: Rect) {
    return this.shelves.find(s => {
      const x = rect.x + rect.width / 2
      const y = rect.y + rect.height / 2
      return s.type === 'area' && x >= s.rect.x && x <= s.rect.x + s.rect.width && y >= s.rect.y && y <= s.rect.y + s.rect.height
    })
  }

  getAreaItems(area: BuilderItem, ignoreItem?: BuilderItem) {
    return this.items.filter(v => v.area === area.id && (!ignoreItem || v.id !== ignoreItem.id))
  }

  calcItemHasRoom(area: BuilderItem, areaItems: BuilderItem[], item: BuilderItem) {
    const overlapItems = findOverlapItems(item.rect, areaItems)
    let hasRoom = overlapItems.length === 0 && !isRectOutsideArea(item.rect, area)

    // if we allow different item stack we should edit this
    if (overlapItems.length >= 1) {
      const nearRect = getNearRect(item.rect, overlapItems[0].rect)
      hasRoom = findOverlapItems(nearRect, areaItems).length === 0 && !isRectOutsideArea(nearRect, area)
      return { hasRoom, nearRect }
    }

    return { hasRoom, nearRect: null }
  }

  positionItemsAfterMovement() {
    this.shelves.filter(v => v.type === 'area').forEach(area => {
      const areaItems = this.getAreaItems(area)
      areaItems.forEach(v => {
        const shadow = this.utils.deepCopy(v)
        if (!shadow) return

        this.calcItemLanding(v, area, shadow)
        if (this.calcItemHasRoom(area, areaItems, shadow).hasRoom) {
          v.rect = shadow.rect
          this.setupItem(v)
          this.setPosition(v, this.planogramBuilder.nativeElement.querySelector('#' + v.id))
        }
      })
    })
  }

  calcItemLanding(item: BuilderItem, closestArea: BuilderItem, targetItem: BuilderItem) {
    targetItem.background = shadowColor
    targetItem.rect.y = closestArea.rect.y + closestArea.rect.height - item.rect.height
    targetItem.imgSrc = ''
    let left = item.rect.x

    if (left < closestArea.rect.x) {
      left = closestArea.rect.x
    } else if (left + item.rect.width > closestArea.rect.x + closestArea.rect.width) {
      left = closestArea.rect.x + closestArea.rect.width - item.rect.width
    }

    const areaItems = this.getAreaItems(closestArea, item)
    const itemUnder = isAboveItem(areaItems, getMidItemPos(item, this.dragData.quantity))
    if (itemUnder && itemUnder.type === 'product' && item.type === 'product' && itemUnder.data.catalogId === item.data.catalogId) {
      targetItem.rect = new Rect(itemUnder.rect.x, itemUnder.rect.y - item.rect.height, item.rect.width, item.rect.height)
      left = itemUnder.rect.x
    }

    // if (snapLeft) {
    //   let lastAreaItem = areaItems[0]

    //   areaItems.forEach(v => {
    //     if (v.rect.x > lastAreaItem.rect.x) {
    //       lastAreaItem = v
    //     }
    //   })
    //   if (lastAreaItem) {
    //     left = lastAreaItem.rect.x + lastAreaItem.rect.width
    //   } else {
    //     left = closestArea.rect.x
    //   }
    // }


    targetItem.rect.x = left
    targetItem.style = {}
    const hasRoom = this.calcItemHasRoom(closestArea, areaItems, targetItem)
    this.itemHasRoom = hasRoom.hasRoom
    if (!this.itemHasRoom) {
      targetItem.background = shadowColorRed
    }

    if (hasRoom.hasRoom && hasRoom.nearRect) {
      targetItem.rect = hasRoom.nearRect
    }

    this.setupItem(targetItem)
  }


  setNewProductItem(event: MouseEvent) {
    try {
      const $elem = (event.target as HTMLElement).closest('.planogram-editor-product-item')
      if (!$elem) return

      const elmData: CatalogData = JSON.parse($elem.querySelector(".hiddenData").innerHTML)
      elmData.thumbnailUrl = elmData.thumbnailUrl?.replace(/&amp;/g, '&')

      const facesInput = $elem.querySelector('.faces-input')
      let quantity = parseInt((facesInput as HTMLInputElement)?.value) || elmData.quantity || 1

      const mousePos = this.calcRelativeMouse(event)
      const x = mousePos.x - elmData.width / 2
      const y = mousePos.y - elmData.height / 2
      const width = elmData.width * quantity
      const item: BuilderItem = {
        id: createTempId(),
        area: '',
        rect: new Rect(x, y, width, elmData.height),
        type: 'product',
        background: 'rgba(11, 87, 208, 0.1)',
        imgSrc: elmData.thumbnailUrl,
        draggable: true,
        autoHeight: false,
        data: elmData,
      }
      this.tempItemData = elmData
      return { item, quantity }

    } catch (error) {
      console.log(error)
    }
  }

  setNewEmptySpace(event: MouseEvent) {
    try {
      const $elem = (event.target as HTMLElement).closest('.planogram-editor-item')
      if (!$elem) return
      const height = parseInt(($elem.querySelector("#emptySpaceHeight") as HTMLInputElement).value)
      const width = parseInt(($elem.querySelector("#emptySpaceWidth") as HTMLInputElement).value)
      const backgroundColor = ($elem.querySelector("#emptySpaceColor") as HTMLInputElement).value
      const mousePos = this.calcRelativeMouse(event)
      const x = mousePos.x - width / 2
      const y = mousePos.y - height / 2
      const item: BuilderItem = {
        id: createTempId(),
        area: '',
        rect: new Rect(x, y, width, height),
        type: 'space',
        background: backgroundColor,
        draggable: true,
        autoHeight: false,
      }
      this.setupItem(item)
      return { item, quantity: 1 }
    } catch (error) {
      console.log(error)
    }
  }

  setNewCategory(event: MouseEvent) {
    const $elem = (event.target as HTMLElement).closest('.planogram-editor-item')
    if (!$elem) return
    const height = 20
    const width = parseInt(($elem.querySelector('#categoryWidth') as HTMLInputElement).value)
    const categoryId = ($elem.querySelector('#categoryId') as HTMLInputElement).value
    const categoryName = ($elem.querySelector('#categoryName') as HTMLInputElement).value
    const backgroundColor = ($elem.querySelector('#categoryColor') as HTMLInputElement).value
    if (!categoryId) {
      event.stopPropagation()
      event.preventDefault()
      this.snackbarService.openSnackBar(2000, this.languageService.translateSync('PleaseSelectCategory'));
      return;
    }
    const mousePos = this.calcRelativeMouse(event)
    const x = mousePos.x - width / 2
    const y = mousePos.y - height / 2

    const item: BuilderItem = {
      id: createTempId(),
      area: '',
      rect: new Rect(x, y, width, height),
      type: 'category',
      text: categoryName,
      background: backgroundColor,
      draggable: true,
      autoHeight: true,
      data: {
        id: categoryId,
        name: categoryName
      }
    }
    this.setupItem(item)
    return { item, quantity: 1 }
  }

  setNewBrand(event: MouseEvent) {
    const $elem = (event.target as HTMLElement).closest('.planogram-editor-item')
    if (!$elem) return
    const height = 20
    const width = parseInt(($elem.querySelector('#brandWidth') as HTMLInputElement).value)
    const brandId = ($elem.querySelector('#brandId') as HTMLInputElement).value
    const brandName = ($elem.querySelector('#brandName') as HTMLInputElement).value
    const backgroundColor = ($elem.querySelector('#brandColor') as HTMLInputElement).value

    if (!brandId) {
      this.snackbarService.openSnackBar(2000, this.languageService.translateSync('PleaseSelectBrand'));
      event.stopPropagation()
      event.preventDefault()
      return;
    }
    const mousePos = this.calcRelativeMouse(event)
    const x = mousePos.x - width / 2
    const y = mousePos.y - height / 2
    const item: BuilderItem = {
      id: createTempId(),
      area: '',
      rect: new Rect(x, y, width, height),
      type: 'brand',
      text: brandName,
      background: backgroundColor,
      draggable: true,
      autoHeight: true,
      data: {
        id: brandId,
        name: brandName
      }
    }
    this.setupItem(item)
    return { item, quantity: 1 }
  }

  outsideBuilder(x: number, y: number) {
    return x < 0 || x > this.width || y < 0 || y > this.height
  }

  startIsClick() {
    clearTimeout(this.clickTimer)
    this.isClick = true
    this.clickTimer = setTimeout(() => {
      this.isClick = false
    }, 300)
  }

  getDraggedElementType(event: MouseEvent) {
    if (this.planogramBuilder.nativeElement.contains(event.target as HTMLElement)) {
      return 'innerDrag'
    } else if ((event.target as HTMLElement).closest('.selector-product')) {
      return 'newProduct'
    } else if ((event.target as HTMLElement).closest('#empty-space-drag')) {
      return 'newEmptySpace'
    } else if ((event.target as HTMLElement).closest('#planogram-editor-brand')) {
      return 'newBrand'
    } else if ((event.target as HTMLElement).closest('#planogram-editor-category')) {
      return 'newCategory'
    } else {
      return ''
    }
  }

  resetDragData() {
    const dragDataReseter: DragData = {
      lastMousePos: null,
      target: null,
      itemCopy: null,
      itemHasRoom: false,
      quantity: 1,
      type: ''
    }
    this.shadowItem = null
    this.tempItemData = null
    this.draggedItem = null
    this.isTempItem = false
    if (this.dragData.target) {
      this.dragData.target.style.zIndex = null
      this.dragData.target.style.cursor = 'grab'
    }
    this.resetDeleteArea()
    Object.entries(dragDataReseter).forEach(([key, value]) => {
      this.dragData[key] = value
    })

  }

  startInnerDrag(event: MouseEvent, dragData: DragData) {
    this.startIsClick()

    let target = event.target as HTMLElement
    if (!target.classList.contains('item')) {
      target = target.closest('.item')
    }
    if (!target) {
      return
    }

    const item = this.items.find(v => v.id === target.id)
    if (!item) return

    this.draggedItem = item
    dragData.itemCopy = this.utils.deepCopy(item)
    dragData.target = target
    dragData.target.style.zIndex = '100'
    dragData.target.style.cursor = 'grabbing'
  }

  startNewItemDrag(newItemData: { item: BuilderItem, quantity: number }, dragData: DragData) {
    this.draggedItem = newItemData.item
    dragData.quantity = newItemData.quantity
    this.draggedItem
    this.isTempItem = true
    this.setupItem(this.draggedItem)
    dragData.target = this.planogramBuilder.nativeElement.querySelector('#' + this.draggedItem.id)
  }

  colorDeleteArea(mousePos: MousePos) {
    const $deleteArea = window.document.body.querySelector("#planogramEditorGondolaContentContainer")
    if (this.outsideBuilder(mousePos.x, mousePos.y)) {
      $deleteArea.classList.add("drag-mode")
    } else {
      $deleteArea.classList.remove("drag-mode")
    }
  }

  resetDeleteArea() {
    window.document.body.querySelector("#planogramEditorGondolaContentContainer").classList.remove("drag-mode")
  }

  mouseMoveHandler(event: MouseEvent, dragData: DragData) {
    const mousePos = this.calcRelativeMouse(event)
    const closestArea = this.findAreaInPos(new Rect(mousePos.x, mousePos.y, 0, 0))
    if (!this.draggedItem) {
      return
    }

    if (!dragData.target) {
      dragData.target = this.planogramBuilder.nativeElement.querySelector('#' + this.draggedItem.id)
    }

    if (this.draggedItem.autoHeight && closestArea) {
      this.draggedItem.rect.height = closestArea.rect.height
      if (this.shadowItem) {
        this.shadowItem.rect.height = closestArea.rect.height
      }
    }

    this.draggedItem.rect = new Rect(
      this.draggedItem.rect.x + mousePos.x - dragData.lastMousePos.x,
      this.draggedItem.rect.y + mousePos.y - dragData.lastMousePos.y,
      this.draggedItem.rect.width,
      this.draggedItem.rect.height
    )

    this.setPosition(this.draggedItem, dragData.target)

    if (closestArea) {
      if (!this.shadowItem) {
        this.shadowItem = this.utils.deepCopy(this.draggedItem)
      }
      this.draggedItem.area = closestArea.id
      this.shadowItem.area = closestArea.id
      this.calcItemLanding(this.draggedItem, closestArea, this.shadowItem)
    } else {
      this.shadowItem = null
    }

    if (dragData.type === 'existingItem') {
      this.colorDeleteArea(mousePos)
    }

    dragData.lastMousePos = mousePos
  }

  finalizeHandlerInnerDrag(dragData: DragData) {
    if (this.isClick) {
      this.selectAreaId(this.draggedItem.area)
    }

    if (this.outsideBuilder(dragData.lastMousePos.x, dragData.lastMousePos.y)) {
      this.items = this.items.filter(v => v.id !== this.draggedItem.id)
    } else if (this.itemHasRoom && this.shadowItem) {
      this.draggedItem.rect = this.shadowItem.rect
      this.draggedItem.area = this.shadowItem.area
      this.setupItem(this.draggedItem)
      this.setPosition(this.draggedItem, dragData.target)
    } else {
      this.draggedItem.rect = dragData.itemCopy.rect
      this.draggedItem.area = dragData.itemCopy.area
      this.setupItem(this.draggedItem)
      this.setPosition(this.draggedItem, dragData.target)
    }

  }

  finalizeHandlerNewItem(dragData: DragData) {
    if (!this.outsideBuilder(dragData.lastMousePos.x, dragData.lastMousePos.y) && this.itemHasRoom) {
      for (let i = 0; i < dragData.quantity; i++) {
        const newItem = this.utils.deepCopy(this.draggedItem)
        newItem.id = createTempId()
        newItem.rect.x = roundNumber(this.shadowItem.rect.x + (i * newItem.rect.width / dragData.quantity))
        newItem.rect.y = this.shadowItem.rect.y
        newItem.rect.width = roundNumber(newItem.rect.width / dragData.quantity)
        newItem.area = this.shadowItem.area
        newItem.area = this.findAreaInPos(new Rect(dragData.lastMousePos.x, dragData.lastMousePos.y, 0, 0)).id
        // this.productsData[newItem.id] = this.utils.deepCopy(this.tempItemData)
        this.setupItem(newItem)
        this.items.push(this.utils.deepCopy(newItem))
      }
    }
  }

  finalizeHandler(dragData: DragData) {
    if (dragData.type === 'existingItem') {
      this.finalizeHandlerInnerDrag(dragData)
    } else if (dragData.type === 'newItem') {
      this.finalizeHandlerNewItem(dragData)
    }

    if (this.selectedAreaId) {
      this.areaChanged(this.selectedAreaId, true)
    }
    this.positionItemsAfterMovement()
    this.resetDragData()
  }


  registerDrag() {
    const mouseDownStream$ = fromEvent(window, 'mousedown');
    const mouseMoveStream$ = fromEvent(window, 'mousemove');
    const mouseUpStream$ = fromEvent(window, 'mouseup');

    this.dragData = {
      lastMousePos: null,
      target: null,
      itemCopy: null,
      itemHasRoom: false,
      quantity: 1,
      type: ''
    }

    this.subscriptions.push(mouseDownStream$.pipe(
      filter((event: MouseEvent) => (event.button === 0)),
      tap((event: MouseEvent) => {
        if (this.draggedItem) return
        this.dragData.lastMousePos = this.calcRelativeMouse(event)
        const draggedElementType = this.getDraggedElementType(event)
        let newDragData = null

        switch (draggedElementType) {
          case 'innerDrag':
            this.startInnerDrag(event, this.dragData)
            this.dragData.type = 'existingItem'
            break;
          case 'newProduct':
            newDragData = this.setNewProductItem(event)
            break;
          case 'newEmptySpace':
            newDragData = this.setNewEmptySpace(event)
            break;
          case 'newCategory':
            newDragData = this.setNewCategory(event)
            break;
          case 'newBrand':
            newDragData = this.setNewBrand(event)
            break;
          default:
            this.resetDragData()
        }

        if (newDragData) {
          this.startNewItemDrag(newDragData, this.dragData)
          this.dragData.type = 'newItem'
        }
      }),
      filter(() => this.draggedItem !== null),
      switchMap(() => mouseMoveStream$.pipe(
        tap((event: MouseEvent) => {
          event.preventDefault()
          this.mouseMoveHandler(event, this.dragData)
        }),
        takeUntil(mouseUpStream$),
        finalize(() => {
          this.finalizeHandler(this.dragData)
        })
      ))
    ).subscribe())
  }

  async createPlanogramImage() {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    const hmul = this.InitialPixelHeight / this.height
    const wmul = this.InitialPixelWidth / this.width
    canvas.height = this.InitialPixelHeight
    canvas.width = this.InitialPixelWidth

    // keep for testing
    // window.document.body.querySelector('#canvas-demo').innerHTML = ''
    // window.document.body.querySelector('#canvas-demo').appendChild(canvas)

    this.shelves.filter(v => v.type === 'area').forEach(v => {
      ctx.fillStyle = v.background || this.equipment.backgroundColor
      ctx.fillRect(v.rect.x * wmul, v.rect.y * hmul, v.rect.width * wmul, v.rect.height * hmul)
    })
    // we draw seperators after areas so areas will not cover them
    this.shelves.filter(v => v.type === 'seperator').forEach(v => {
      ctx.fillStyle = v.background || this.equipment.backgroundColor
      const height = v.rect.height * hmul || 2
      const width = v.rect.width * wmul || 2
      ctx.fillRect(v.rect.x * wmul, v.rect.y * hmul, width, height)
    })

    // draw border
    ctx.strokeStyle = 'black'
    ctx.lineWidth = 3
    ctx.strokeRect(0, 0, this.InitialPixelWidth, this.InitialPixelHeight)

    const promises = []
    for (const item of this.items) {
      const x = item.rect.x * wmul
      const y = item.rect.y * hmul
      const width = item.rect.width * wmul
      const height = item.rect.height * hmul
      if (item.imgSrc) {
        const newPromise = new Promise((resolve, reject) => {
          const img = new Image()
          img.onload = () => {
            resolve(
              ctx.drawImage(img, x, y, width, height)
            )
          }
          img.setAttribute('crossorigin', 'anonymous');
          img.onerror = () => {
            ctx.fillStyle = 'lightgray'
            ctx.fillRect(x, y, width, height)
            ctx.fillStyle = '#000';
            ctx.font = '20px Arial'
            ctx.fillText('No Image', x, (height / 2) + y, width)
            resolve(1)
          }
          img.src = item.imgSrc
        })
        promises.push(newPromise)
      } else {
        ctx.fillStyle = item.background || this.equipment.backgroundColor
        ctx.fillRect(x, y, width, height)
        if (item.text) {
          ctx.fillStyle = '#000';
          ctx.font = '20px Arial'
          ctx.fillText(item.text, x, (height / 2) + y, width)
        }
      }
    }

    await Promise.all(promises)
    const canvasImage = canvas.toDataURL('image/png')
    const $a = document.createElement('a')
    $a.href = canvasImage
    $a.download = this.planogram.name + '.png'
    $a.dataset.downloadurl = [
      'image/png',
      $a.download,
      $a.href,
    ].join(":");
    return $a
  }

  preparePlanogramStructure() {
    const structure: Structure = {
      width: this.width,
      height: this.height,
      areas: this.shelves.map(v => prepareStructureArea(v, this.items))
    }
    return structure
  }
}
